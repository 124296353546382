import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { useWindowSize } from '../../../hooks/useWindowSize';

import { ReactComponent as ProfileIcon } from '../../../assets/icons/email-profile.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/icons/logout.svg';

// locales
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/i18n';

import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { selectProfile } from 'store/profile/selectors';
import { selectIsAuthenticated } from 'store/app/selectors';

// styles
import {
  Wrapper,
  ModalContainer,
  ModalOverlay,
  ModalTitle,
  ModalMenu,
  ModalMenuItem,
  ModalMenuItemChildren,
  ModalMenuItemChildrenLink,
  LogoOuter,
  ModalActions,
  Link,
  ButtonProstir,
} from './styles';

import { menu } from '../../../config/menu';

import { findLocationPath } from '../../../utils/findLocationPath';
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-right.svg';
import { ReactComponent as Logo } from '../../../assets/images/logo_cardio_new.svg';

interface IProps {
  open: boolean;
  close: () => void;
}

export function NavMenu({ open, close }: IProps) {
  const [activeSlide, setActiveSlide] = useState<string | null>(null);
  const [pageTitle, setPageTitle] = useState<string | null>(null);

  // locales hook
  const { t } = useTranslation();

  // selector
  const profileInfo = useSelector(selectProfile);
  const isLoggedIn = useSelector(selectIsAuthenticated);

  // location hook
  const location = useLocation();

  useEffect(() => {
    if (location.pathname) {
      const path = findLocationPath(location.pathname);

      if (!location.pathname.includes('/auth')) {
        setPageTitle(path);
      }
    }

    close();
  }, [location]);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 1280) close();
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // @ts-ignore
  return (
    <Wrapper open={open}>
      <ModalContainer open={open}>
        {/*{pageTitle && <ModalTitle>{t(translations.nav[pageTitle])}</ModalTitle>}*/}

        <Link to={'/'}>
          <LogoOuter>
            <Logo height={40} />
          </LogoOuter>
        </Link>
        <ModalMenu>
          {menu.map(item => (
            <div key={item.name}>
              {item.name === 'main' ? (
                <Link to={item.slug || ''}>
                  <ModalMenuItem category active={activeSlide === item.name}>
                    {t(translations.nav[item.name])}
                  </ModalMenuItem>
                </Link>
              ) : (
                <Link to={item.slug || ''}>
                  <ModalMenuItem
                    category
                    active={item.slug === location.pathname}
                  >
                    {t(translations.nav[item.name])}
                  </ModalMenuItem>
                </Link>
              )}

              {/*{item.submenu && (*/}
              {/*  <ModalMenuItemChildren*/}
              {/*    length={item.submenu.length}*/}
              {/*    active={activeSlide === item.name}*/}
              {/*  >*/}
              {/*    {item.submenu.map(sub => (*/}
              {/*      <Link key={sub.name} to={sub.slug}>*/}
              {/*        <ModalMenuItem>*/}
              {/*          {t(translations.nav[sub.name])}*/}
              {/*        </ModalMenuItem>*/}
              {/*      </Link>*/}
              {/*    ))}*/}
              {/*  </ModalMenuItemChildren>*/}
              {/*)}*/}

              {item && item.hasOwnProperty('submenu') && item.name !== 'main' && (
                <ModalMenuItemChildren
                  length={item['submenu'].length}
                  active={true}
                >
                  {item['submenu'].map(sub => (
                    <Link
                      key={sub.name}
                      to={sub.slug}
                      onClick={() => {
                        if (isLoggedIn) {
                          const tagManagerArgs = {
                            gtmId: process.env.REACT_APP_GTM_ID,
                            dataLayer: {
                              event: 'menu_header_click', //Статическое значение.
                              category: 'Menu Header Click', //Статическое значение
                              action: t(translations.nav[item.name]), //Название раздела меню (верхний уровень) к которому принадлежит пункт меню.
                              label: t(translations.nav[sub.name]), //Название пункта меню, по которому кликнул пользователь.
                              uid: profileInfo?.data?.id, //User ID. Id зарегистрированного пользователя.
                            },
                          };

                          TagManager.initialize(tagManagerArgs);
                        }
                      }}
                    >
                      <ModalMenuItemChildrenLink>
                        {t(translations.nav[sub.name])}
                      </ModalMenuItemChildrenLink>
                    </Link>
                  ))}
                </ModalMenuItemChildren>
              )}
            </div>
          ))}
          <Link
            to={isLoggedIn ? '/profile' : '/login?redirect_to=profile'}
            onClick={() => {
              if (isLoggedIn) {
                const tagManagerArgs = {
                  gtmId: process.env.REACT_APP_GTM_ID,
                  dataLayer: {
                    event: 'menu_header_click', //Статическое значение.
                    category: 'Menu Header Click', //Статическое значение
                    action: 'Особистий кабінет', //Название раздела меню (верхний уровень) к которому принадлежит пункт меню.
                    label: 'Особистий кабінет', //Название пункта меню, по которому кликнул пользователь.
                    uid: profileInfo?.data?.id, //User ID. Id зарегистрированного пользователя.
                  },
                };

                TagManager.initialize(tagManagerArgs);
              }
            }}
          >
            <ModalMenuItem category>Особистий кабінет</ModalMenuItem>
          </Link>
        </ModalMenu>

        {/*<ModalActions>*/}
        {/*  <Link*/}
        {/*    to={{*/}
        {/*      pathname: `/auth/login`,*/}
        {/*      state: { background: location },*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <ModalMenuItem>*/}
        {/*      <ProfileIcon />*/}
        {/*      {t(translations.header.userActionButton)}*/}
        {/*    </ModalMenuItem>*/}
        {/*  </Link>*/}

        {/*  <ModalMenuItem onClick={() => console.log('logout')}>*/}
        {/*    <LogoutIcon />*/}
        {/*    {t(translations.header.logoutButton)}*/}
        {/*  </ModalMenuItem>*/}
        {/*</ModalActions>*/}
      </ModalContainer>

      <ModalOverlay open={open} onClick={close} />
    </Wrapper>
  );
}

export default NavMenu;
