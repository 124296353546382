import * as React from 'react';

import { useLocation } from 'react-router-dom';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export function AuthPage() {
  const query = useQuery();

  let token: any = query.get('savedToken');
  let rememberToken: any = query.get('savedRememberToken');
  let savedCookieAccepted: any = query.get('savedCookieAccepted');
  let redirectTo: any = query.get('redirectTo');

  if (token && '' !== token) {
    localStorage.setItem('access_token', token);
  }

  if (rememberToken && '' !== rememberToken) {
    localStorage.setItem('remember_token', rememberToken);
  }

  if (savedCookieAccepted && '' !== savedCookieAccepted) {
    localStorage.setItem('cookies_accepted', savedCookieAccepted);
  }

  window.location.href = redirectTo;

  return null;
}
