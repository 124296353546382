import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { useLocation } from 'react-router-dom';

// import { ReactComponent as Logo } from '../../../assets/images/logo-last.svg';
import { ReactComponent as Logo } from '../../../assets/images/logo_cardio_new.svg';
import { ReactComponent as UserIcon } from '../../../assets/icons/user2.svg';
import { ReactComponent as UserMobileIcon } from '../../../assets/icons/user-mobile.svg';
import { ReactComponent as MenuBookmark } from '../../../assets/icons/menu-bookmark.svg';
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-right.svg';
import { ReactComponent as ProstirServierBtn } from '../../../assets/images/logo-prostir-servier.svg';

// locales
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/i18n';

// store
import {
  sliceKey as appSliceKey,
  reducer as appReducer,
  actions as appActions,
} from '../../../store/app/slice';

import { selectIsAuthenticated } from '../../../store/app/selectors';

import {
  sliceKey as profileSliceKey,
  reducer as profileReducer,
  actions as profileActions,
} from '../../../store/profile/slice';

import { profileSaga } from '../../../store/profile/saga';

import { selectProfileData } from '../../../store/profile/selectors';

// components
import MenuItem from './MenuItem';
import StyledBurger from '../../../atoms/Burger';
import BurgerNavMenu from '../NavMenu';

// styles
import {
  Wrapper,
  Inner,
  ActionsContainer,
  LogoOuter,
  NavMenu,
  Develop,
  UserActionButton,
  ButtonIcon,
  ButtonIconBookmark,
  ButtonsContainer,
  ButtonProstir,
  Phone,
  Link,
} from './styles';

import { menu } from '../../../config/menu';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { LogoWrapper, TextLogo } from '../Header/styles';
import { FooterCenteredText2 } from '../Footer/styles';
import {
  actions as favoritesActions,
  reducer as favoritesReducer,
  sliceKey as favoritesSliceKey,
} from '../../../store/favorites/slice';
import { favoritesSaga } from '../../../store/favorites/saga';
import {
  selectFavoritesData,
  selectFavoritesDone,
} from '../../../store/favorites/selectors';

export default function NavBar() {
  // inject reducer && saga
  useInjectSaga({ key: profileSliceKey, saga: profileSaga });
  useInjectReducer({ key: appSliceKey, reducer: appReducer });

  useInjectReducer({ key: profileSliceKey, reducer: profileReducer });

  // inject reducer && saga for favorites
  useInjectSaga({ key: favoritesSliceKey, saga: favoritesSaga });
  useInjectReducer({ key: favoritesSliceKey, reducer: favoritesReducer });

  // local state
  const [burgerOpened, setBurgerOpened] = useState(false);

  const [isSticky, setSticky] = useState(true);

  // locales hook
  const { t } = useTranslation();

  // location hook
  const location = useLocation();

  // window size watcher
  const { width } = useWindowSize();

  // dispatch
  const dispatch = useDispatch();
  const getProfile = () => dispatch(profileActions.fetchTrigger());
  const getFavorites = () => dispatch(favoritesActions.fetchTrigger());

  // selector
  const isLoggedIn = useSelector(selectIsAuthenticated);

  const profileData = useSelector(selectProfileData);

  const favoritesData = useSelector(selectFavoritesData);

  const wrapperRef = useRef(null);

  // console.log(favoritesData)

  // useEffect(() => {
  //   setSticky(
  //     window.innerWidth < 1025 ||
  //       (location.pathname !== '/' && !location.pathname.includes('/auth')),
  //   );
  //
  //   // @ts-ignore
  //   const sticky = wrapperRef.current.getBoundingClientRect().top;
  //
  //   const scrollHandler = () => {
  //     setSticky(
  //       window.pageYOffset > sticky ||
  //         width < 1025 ||
  //         (location.pathname !== '/' && !location.pathname.includes('/auth')),
  //     );
  //   };
  //
  //   window.addEventListener('scroll', scrollHandler);
  //
  //   return () => {
  //     window.removeEventListener('scroll', scrollHandler);
  //   };
  // }, [width, location]);

  useEffect(() => {
    if (!profileData && isLoggedIn) {
      getProfile();
    }
  }, [profileData]);

  useEffect(() => {
    if (isLoggedIn) {
      getFavorites();
    }
  }, []);

  return (
    <Wrapper isSticky={isSticky} ref={wrapperRef}>
      <Inner isSticky={isSticky}>
        {isSticky && (
          <Link to={'/'}>
            <LogoOuter>
              <Logo height={width < 1439 ? 40 : 48} />
            </LogoOuter>
          </Link>
        )}
        {width > 1279 ? (
          <>
            <NavMenu>
              {menu.map(item => (
                <MenuItem
                  key={item.name}
                  slug={item.slug}
                  name={item.name}
                  submenu={item['submenu']}
                  active={isSticky}
                  currentLocation={location.pathname}
                />
              ))}
            </NavMenu>

            {isLoggedIn && profileData ? (
              <ButtonsContainer>
                <ButtonProstir
                  to={{ pathname: `${process.env.REACT_APP_BASE_PROMO_URL}` }}
                  target="_blank"
                >
                  <ProstirServierBtn />
                </ButtonProstir>
                <Link to={'/profile/saved'}>
                  <ButtonIconBookmark>
                    {favoritesData && favoritesData['meta']['total'] > 0 && (
                      <span>{favoritesData['meta']['total']}</span>
                    )}
                    <MenuBookmark />
                  </ButtonIconBookmark>
                </Link>
                <Link to={'/profile'}>
                  <UserActionButton isSticky={isSticky}>
                    <ButtonIcon isSticky={isSticky}>
                      <UserIcon />
                    </ButtonIcon>

                    {profileData.firstName + ' ' + profileData.lastName}
                  </UserActionButton>
                </Link>
              </ButtonsContainer>
            ) : (
              <ButtonsContainer>
                <ButtonProstir
                  to={{ pathname: `${process.env.REACT_APP_BASE_PROMO_URL}` }}
                  target="_blank"
                >
                  <ProstirServierBtn />
                </ButtonProstir>
                {/* <Link to={'/login?redirect_to=profile/saved'}>
                  <ButtonIcon>
                    <MenuBookmark />
                  </ButtonIcon>
                </Link> */}
                <Link to={'/login?redirect_to=profile'}>
                  <UserActionButton
                    // onClick={() => dispatch(appActions.setLocation('/profile'))}
                    isSticky={isSticky}
                  >
                    <ButtonIcon isSticky={isSticky}>
                      <UserIcon />
                    </ButtonIcon>

                    {t(translations.header.userActionButton)}
                  </UserActionButton>
                </Link>
              </ButtonsContainer>
            )}
          </>
        ) : (
          <ActionsContainer>
            {/*<Phone href={`tel:+${t(translations.header.phone)}`}>*/}
            {/*  <PhoneIcon />*/}
            {/*  {width > 480 && '+' + t(translations.header.phone)}*/}
            {/*</Phone>*/}
            <ButtonProstir
              to={{ pathname: `${process.env.REACT_APP_BASE_PROMO_URL}` }}
              target="_blank"
            >
              <ProstirServierBtn />
            </ButtonProstir>
            {isLoggedIn && profileData ? (
              <Link to={'/profile'}>
                <UserActionButton isSticky={isSticky}>
                  <ButtonIcon className={'mobileIcon'} isSticky={isSticky}>
                    <UserMobileIcon />
                  </ButtonIcon>
                </UserActionButton>
              </Link>
            ) : (
              <Link to={'/login?redirect_to=profile'}>
                <UserActionButton isSticky={isSticky}>
                  <ButtonIcon className={'mobileIcon'} isSticky={isSticky}>
                    <UserMobileIcon />
                  </ButtonIcon>
                </UserActionButton>
              </Link>
            )}

            <StyledBurger
              open={burgerOpened}
              onClick={() => setBurgerOpened(!burgerOpened)}
            >
              <div />
              <div />
              <div />
            </StyledBurger>
          </ActionsContainer>
        )}
        {/*<NavMenu>*/}
        {/*  {menu.map(item => (*/}
        {/*    <MenuItem*/}
        {/*      key={item.name}*/}
        {/*      slug={item.slug}*/}
        {/*      name={item.name}*/}
        {/*      submenu={item.submenu}*/}
        {/*      active={isSticky}*/}
        {/*      currentLocation={location.pathname}*/}
        {/*    />*/}
        {/*  ))}*/}
        {/*</NavMenu>*/}
      </Inner>

      <BurgerNavMenu open={burgerOpened} close={() => setBurgerOpened(false)} />
    </Wrapper>
  );
}
