import React from 'react';
import axios from 'axios';

// // locales
// import { useTranslation } from 'react-i18next';
// import { translations } from 'locales/i18n';

import logo from '../../../assets/images/logo_servier_new.svg';

import { ReactComponent as Phone } from '../../../assets/icons/phone.svg';
import { ReactComponent as Mail } from '../../../assets/icons/mail.svg';
import { ReactComponent as Instagram } from '../../../assets/icons/instagram.svg';
import { ReactComponent as Viber } from '../../../assets/icons/viber.svg';
import { ReactComponent as Telegram } from '../../../assets/icons/telegram.svg';
import { ReactComponent as Facebook } from '../../../assets/icons/facebook-logo.svg';

// components
import FeedbackPopup from '../../features/FeedbackPopup';

// styles
import {
  Wrapper,
  LinkTo,
  Contact,
  Social,
  IconItem,
  FooterMainWrapper,
  FooterMain,
  LogoImg,
  FooterCenteredText,
  SocialWraper,
  Collumn,
} from './styles';
import { DownloadPdf } from '../../features/CardsList/CardsListItem/styles';
import { Div } from 'containers/HomePage/styles';

// api breakpoint
const baseUrl = process.env.REACT_APP_API_BASE_URL + 'api/v1/translations';

export function Footer() {
  // locales hook
  // const { t } = useTranslation();

  // fetch data from api
  const [translation, setTranslation] = React.useState({
    footer_phone: '',
    footer_fax: '',
    footer_phone_1: '',
    footer_phone_2: '',
    footer_email_1: '',
    footer_email_2: '',
    footer_name: '',
    footer_description: '',
    footer_address_1: '',
    footer_address_2: '',
  });

  const fetchTranslation = () => {
    axios
      .get(`${baseUrl}`)
      .then(response => {
        const translated = response.data;

        setTranslation(translated);
      })
      .catch(error => console.error(`Error: ${error}`));
  };

  React.useEffect(() => {
    fetchTranslation();
  }, []);

  const {
    // footer_phone,
    // footer_fax,
    // footer_phone_1,
    // footer_phone_2,
    // footer_email_1,
    // footer_email_2,
    // footer_name,
    // footer_description,
    footer_address_1,
    // footer_address_2,
  } = translation;

  return (
    <Wrapper>
      <FooterMainWrapper>
        <FooterMain>
          <LinkTo href="https://servier.ua/" target="_blank">
            <LogoImg width={150} height={30} src={logo} />
          </LinkTo>
          <Collumn>
            <LinkTo
              href="https://servier.ua/kontakty/#%d0%bf%d0%be%d0%b2%d1%96%d0%b4%d0%be%d0%bc%d0%bb%d0%b5%d0%bd%d0%bd%d1%8f-%d0%bf%d1%80%d0%be-%d0%bf%d0%be%d0%b1%d1%96%d1%87%d0%bd%d0%b5-%d1%8f%d0%b2%d0%b8%d1%89%d0%b5-%d0%bd%d0%b5%d0%b1%d0%b0%d0%b6"
              target="_blank"
            >
              Повідомити побічну реакцію
            </LinkTo>
            <LinkTo href="https://servier.ua/ukr/contact" target="_blank">
              Форми для зв'язку
            </LinkTo>

            <LinkTo
              href={process.env.PUBLIC_URL + '/korisni-posilannya'}
              target="_blank"
            >
              Корисні посилання
            </LinkTo>
            <LinkTo href={process.env.PUBLIC_URL + '/news'} target="_blank">
              Новини
            </LinkTo>
          </Collumn>

          <Collumn>
            <LinkTo
              href={process.env.PUBLIC_URL + '/clinical-cases'}
              target="_blank"
            >
              Клінічні випадки
            </LinkTo>
            {/* <LinkTo href="#" target="_blank">
              Події
            </LinkTo> */}
            <LinkTo href={process.env.PUBLIC_URL + '/webinars'} target="_blank">
              Вебінари
            </LinkTo>
            <LinkTo
              href={process.env.PUBLIC_URL + '/standarty-likuvannya'}
              target="_blank"
            >
              Стандарти лікування
            </LinkTo>
          </Collumn>

          <Collumn>
            <LinkTo
              href={process.env.PUBLIC_URL + '/doctor-and-visitor'}
              target="_blank"
            >
              Поділитися з пацієнтом
            </LinkTo>
            <LinkTo
              href={process.env.PUBLIC_URL + '/policies/privacy'}
              target="_blank"
            >
              Політика конфіденційності
            </LinkTo>
            <LinkTo
              href={process.env.PUBLIC_URL + '/policies/cookie'}
              target="_blank"
            >
              Політика використання cookie
            </LinkTo>
          </Collumn>

          <SocialWraper>
            <Social>
              <Contact
                href={`https://www.instagram.com/cardioprostir/`}
                target={'_blank'}
              >
                <IconItem>
                  <Instagram width={26} height={26} />
                </IconItem>
              </Contact>

              <Contact href={`https://cutt.ly/axtX81w`} target={'_blank'}>
                <IconItem>
                  <Viber width={26} height={26} />
                </IconItem>
              </Contact>

              <Contact
                href={`https://t.me/joinchat/AAAAAE_nsfR1F7TeevcsUQ`}
                target={'_blank'}
              >
                <IconItem>
                  <Telegram width={26} height={26} />
                </IconItem>
              </Contact>

              <Contact
                href={`https://www.facebook.com/cardioprostir/`}
                target={'_blank'}
              >
                <IconItem>
                  <Facebook width={26} height={26} fill={'#ffffff'} />
                </IconItem>
              </Contact>
            </Social>
            <LinkTo href="tel:+380 44 490 34 41" target="_blank">
              Телефон: +380 44 490 34 41
            </LinkTo>
          </SocialWraper>
        </FooterMain>
        <FooterCenteredText>
          <span>{footer_address_1.replace(/\n/g, ' ')}</span>
          <span>{`Copyright © ${new Date().getFullYear()}, ТОВ Серв’є Україна. Усі права захищені.`}</span>
        </FooterCenteredText>
      </FooterMainWrapper>
    </Wrapper>
  );
}
