import React, { useEffect } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsAuthenticated } from '../store/app/selectors';
import ModalLayout from '../layouts/ModalLayout';
import { UnauthorizedPage } from '../containers/UnauthorizedPage/Loadable';
import { actions as appActions } from '../store/app/slice';
import axios from 'axios';

export const PrivateRoute = ({
  path,
  resourceType,
  component: Component,
  ...rest
}: any) => {
  const isLoggedIn = useSelector(selectIsAuthenticated);
  const [allow, setAllow] = React.useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const segments = location.pathname.replace(/^\/+|\/+$/g, '').split('/');
  const slug = segments[segments.length - 1];
  let deepRequestSent = false;

  useEffect(() => {
    dispatch(appActions.setLocation(location.pathname));
    if (isLoggedIn) setAllow(true);
    else if (!isLoggedIn && !deepRequestSent && resourceType) {
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}api/v1/deep/main/${resourceType}/${slug}`,
        )
        .then(response => {
          if (response.data == 1) {
            setAllow(true);
          }
        });
    }
  }, []);

  return (
    <Route
      {...rest}
      render={props => {
        if (allow) return <Component {...props} />;
        else
          return (
            <ModalLayout isUnauthorised>
              <UnauthorizedPage />
            </ModalLayout>
          );
      }}
    />
  );
};
