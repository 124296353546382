/**
 * app.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from 'serviceWorker';

// Use consistent styling
import 'sanitize.css/sanitize.css';

// Import root app
import App from 'app';

import { HelmetProvider } from 'react-helmet-async';

import { configureAppStore } from 'store/configureStore';

// import tag manager
// import TagManager from 'react-gtm-module';

import ReactPixel from 'react-facebook-pixel';

// Initialize languages
import './locales/i18n';

import { ThemeProvider } from 'styles/theme/ThemeProvider';

const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

// const tagManagerArgs = {
//   gtmId: process.env.REACT_APP_GTM_ID,
//   // gtmId: 'GTM-MZJH9Q3-1__Remove',
// };
//
// TagManager.initialize(tagManagerArgs);

const advancedMatching = { em: 'cardioprostir.map@gmail.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

// @ts-ignore
ReactPixel.init('598781201630583', advancedMatching, options);

ReactPixel.pageView(); // For tracking page view

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider>
      <HelmetProvider>
        {/*<React.StrictMode>*/}
        <App />
        {/*</React.StrictMode>*/}
      </HelmetProvider>
    </ThemeProvider>
  </Provider>,
  MOUNT_NODE,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
