import styled from 'styled-components/macro';

import { NavLink } from 'react-router-dom';

import mq from '../../../utils/mediaQueriesBp';

import ResponsiveContainer from '../ResponsiveContainer';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopBarContainer = styled.div`
  width: 100%;
  background-color: ${p => p.theme.colors.nav_purple};
  height: 57px;

  ${mq('middle')} {
    height: 69px;
  }
`;

const TopBarInner = styled(ResponsiveContainer)``;

const TopBar = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  // padding-top: 10px;
  // padding-bottom: 10px;
`;

const LogoWrapper = styled(NavLink)`
  text-decoration: none;
  width: 230px;
  transition: all 0.5s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const TopInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 400px;

  ${mq('desktop')} {
    max-width: unset;
  }
`;

const Phone = styled.a`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: white;
  cursor: pointer;
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 0.5s ease;

  &:hover {
    opacity: 0.8;
    transform: scale(1.05);
  }

  ${mq('tablet')} {
    font-size: 20px;
    line-height: 24px;
  }

  ${mq('laptop')} {
    font-size: 26px;
    line-height: 30px;
  }

  & > :first-child {
    margin-right: 10px;
  }
`;

const Text = styled.p`
  font-weight: 300;
  font-size: 20px;
  line-height: 19px;
  color: white;
  text-align: right;
`;
const TextLogo = styled.p`
  font-weight: bold;
  font-style: italic;
  color: #ffffff;
  text-transform: uppercase;
  margin-left: 10px;
  line-height: 13px;
  font-size: 16px;
  margin-bottom: -2px;
`;
const CookiesWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  z-index: 1005;
`;
const Cookies = styled.div`
  width: 100%;
  height: 80px;
  background: #0071ba;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15%;
  z-index: 1006;
`;
const CookiesText = styled.div`
  color: white;
  font-weight: 500;
  font-size: 18px;
`;

const CookiesButton = styled.div`
  width: 120px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #95c11f;
  color: white;
  cursor: pointer;
`;
const NavBackBox = styled.div`
  width: 100%;
  height: 57px;

  ${mq('middle')} {
    height: 69px;
  }
`;
const PreloaderWrap = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  z-index: 1006;
  display: flex;
  align-items: center;
  justify-content: center;
  svg path {
    stroke-dasharray: 281;
    -webkit-animation: dash 5s infinite linear forwards;
  }
  @-webkit-keyframes dash {
    from {
      stroke-dashoffset: 814;
    }

    to {
      stroke-dashoffset: -814;
    }
  }

  @keyframes dash {
    from {
      stroke-dashoffset: 814;
    }

    to {
      stroke-dashoffset: -814;
    }
  }
`;

export {
  Wrapper,
  TopBarContainer,
  TopBarInner,
  TopBar,
  TopInfo,
  Phone,
  Text,
  NavBackBox,
  TextLogo,
  LogoWrapper,
  PreloaderWrap,
  Cookies,
  CookiesWrapper,
  CookiesButton,
  CookiesText,
};
