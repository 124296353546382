import styled from 'styled-components/macro';

import mq from '../../../utils/mediaQueriesBp';

import ResponsiveContainer from '../ResponsiveContainer';

const Wrapper = styled.div`
  display: flex;
  //margin-top: auto;
  flex-direction: column;

  hr {
    height: 1px;
    width: 100%;
    background-color: ${p => p.theme.colors.darkBlue};
    color: ${p => p.theme.colors.darkBlue};
    border: none;
    margin: 20px 0;
  }
`;

const LinkTo = styled.a`
  display: inline-block;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.172;
  color: white;
  text-decoration: none;
  position: relative;
  ${props => props.theme.hover.transition}

  &:hover {
    color: ${p => p.theme.colors.cyan};
  }

  ${mq('desktop')} {
    grid-row: unset;
  }
`;

const Contact = styled.a`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${p => p.theme.colors.black};
  text-decoration: none;
  margin-right: 30px;
  white-space: nowrap;

  &:last-child {
    margin-right: 0;
  }

  ${mq('large')} {
    font-size: ${(24 / 1920) * 100}vw;
    line-height: ${(28 / 1920) * 100}vw;
  }
`;

const IconItem = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${p => p.theme.hover.transition}

  svg {
    fill: #ffffff;
    ${p => p.theme.hover.transition}

    g {
      path {
        ${p => p.theme.hover.transition}
      }
    }
  }

  &:hover {
    svg {
      fill: ${p => p.theme.colors.cyan};
      g {
        path {
          fill: ${p => p.theme.colors.cyan};
        }
      }
    }
  }

  ${mq('large')} {
    width: 20px;
    height: 20px;
  }
`;

const Social = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;

  ${mq('laptop')} {
    grid-row: 2;
    grid-column: 3/-1;
    margin-top: 0;
  }

  ${mq('desktop')} {
    grid-row: unset;
    grid-column: unset;
  }
`;

const FooterMainWrapper = styled.div`
  width: 100%;
  padding-bottom: 20px;
  background-color: ${p => p.theme.colors.nav_purple};
`;

const FooterMain = styled(ResponsiveContainer)`
  display: grid;
  row-gap: 10px;
  justify-content: center;
  align-items: center;
  color: white;
  padding-top: 35px;
  padding-bottom: 35px;

  ${mq('laptop')} {
    grid-template-columns: repeat(4, max-content);
    justify-content: space-between;
    row-gap: 40px;
    padding-top: 57px;
    padding-bottom: 45px;
  }

  ${mq('desktop')} {
    grid-template-columns: repeat(5, max-content);
  }
`;

const LogoImg = styled.img`
  object-fit: contain;
  max-width: 150px;
  margin-bottom: 30px;

  ${mq('laptop')} {
    grid-row: 2;
    grid-column: 1/3;
    margin-bottom: 0;
  }

  ${mq('desktop')} {
    margin: 0;
    grid-row: unset;
    grid-column: unset;
  }
`;

const Collumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
`;

const SocialWraper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;

const FooterTextLight = styled.p`
  font-size: 12px;
  line-height: 1.172;
  font-weight: 400;
  color: ${props => props.theme.colors.lightPurple};
  white-space: pre-wrap;
`;

const FooterCenteredText = styled(FooterTextLight)`
  text-align: center;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const FooterCenteredText2 = styled(FooterTextLight)`
  line-height: 16px;
  text-align: center;
  margin-top: 10px;
  font-weight: bold;
`;

export {
  Wrapper,
  Social,
  Contact,
  IconItem,
  FooterMainWrapper,
  FooterMain,
  LogoImg,
  FooterTextLight,
  FooterCenteredText,
  FooterCenteredText2,
  LinkTo,
  SocialWraper,
  Collumn,
};
