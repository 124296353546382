import styled from 'styled-components/macro';

import { NavLink } from 'react-router-dom';

// components
import ResponsiveContainer from '../ResponsiveContainer';

// utils
import mq from '../../../utils/mediaQueriesBp';

interface NavProps {
  isSticky?: boolean;
}

const Wrapper = styled.div<NavProps>`
  position: ${p => (p.isSticky ? 'fixed' : 'relative')};
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${p => (p.isSticky ? p.theme.colors.nav_purple : '')};
  transition: background-color 0.5s;
  z-index: 999;
`;

const Inner = styled(ResponsiveContainer)<NavProps>`
  padding: 0 20px;
  min-height: 57px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const TextLogo = styled.p`
  font-weight: bold;
  font-style: italic;
  color: #ffffff;
  text-transform: uppercase;
  margin-left: 10px;
  line-height: 13px;
  font-size: 16px;
  margin-bottom: -2px;
`;

const LogoOuter = styled.div<NavProps>`
  display: flex;
  align-items: flex-end;
  max-width: 140px;
  margin-right: 16px;
  transition: all 0.5s ease;

  ${mq('laptop')} {
    max-width: unset;
    width: 227px;
    margin-right: 0;
  }

  svg {
    width: 100%;
    object-fit: cover;
  }
  &:hover {
    transform: scale(1.05);
  }
`;

const NavMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;

  ${mq('large')} {
    margin-left: 20px;
  }
`;
const Develop = styled.div<NavProps>`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  display: ${p => (p.isSticky ? 'none' : 'block')};
  color: #ef4161;
`;

const ButtonIcon = styled.div<NavProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 26px;
  height: 26px;
  margin-right: 5px;

  svg {
    path {
      ${p => p.theme.hover.transition}
    }
  }

  &.mobileIcon {
    width: 30px;
    height: 30px;
    padding: 5px;
  }
`;

const StyledLink = styled(NavLink)`
  text-decoration: none;

  ${mq('desktop')} {
    &:hover {
      ${ButtonIcon} {
        svg {
          path {
            fill: ${p => p.theme.colors.cyan};
          }
        }
      }
    }
  }
`;

const UserActionButton = styled.div<NavProps>`
  display: flex;
  align-items: center;
  color: ${p => (p.isSticky ? 'white' : p.theme.colors.primary)};
  background-color: transparent;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  padding-right: ${p => (p.isSticky ? '10px' : 0)};
  border: none;
  ${p => p.theme.hover.transition}

  ${mq('desktop')} {
    &:hover {
      color: ${p => p.theme.colors.cyan};
    }
  }

  ${mq('large')} {
    padding-right: ${p => (p.isSticky ? '20px' : 0)};
    white-space: nowrap;
  }
`;

const ButtonIconBookmark = styled.div<NavProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 26px;
  height: 26px;
  margin-right: 10px;
  position: relative;

  span {
    font-size: 10px;
    width: 14px;
    height: 14px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e1425f;
    position: absolute;
    right: -2px;
    top: -2px;
    border-radius: 50px;
    font-weight: 500;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonProstir = styled(NavLink)`
  margin-right: 12px;

  ${mq('desktop')} {
    margin-right: 16px;
  }

  svg {
    max-width: 130px;
    width: 100%;
  }

  // padding: 8px 4px 8px 12px;
  // background: #ffffff;
  // border-radius: 4px;
  // color: ${p => p.theme.colors.primary};
  // font-size: 13px;
  // font-weight: 500;
  // cursor: pointer;
  // display: flex;
  // align-items: center;
  // white-space: nowrap;
  // margin-right: 16px;
  // text-decoration: none;
  // ${p => p.theme.hover.transition}

  // svg {
  //   width: 20px;
  //   height: 18px;
  //   path {
  //     fill: ${p => p.theme.colors.primary};
  //     ${p => p.theme.hover.transition}
  //   }
  // }
  //
  // &:hover {
  //   background-color: ${p => p.theme.colors.cyan};
  //   color: ${p => p.theme.colors.nav_purple};
  //
  //   svg {
  //     path {
  //       fill: ${p => p.theme.colors.nav_purple};
  //     }
  //   }
  // }
`;

const Phone = styled.a`
  display: flex;
  align-items: center;
  margin-right: 10px;
  color: white;
  cursor: pointer;
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 0.5s ease;

  &:hover {
    opacity: 0.8;
  }

  & > :first-child {
    margin-right: 10px;
  }

  ${mq('tablet')} {
    font-size: 20px;
    line-height: 24px;
  }
`;

export {
  Wrapper,
  Inner,
  ActionsContainer,
  LogoOuter,
  NavMenu,
  UserActionButton,
  TextLogo,
  ButtonIcon,
  ButtonIconBookmark,
  ButtonsContainer,
  ButtonProstir,
  StyledLink as Link,
  Phone,
  Develop,
};
