export const menu = [
  {
    name: 'news',
    slug: '/news',
    // submenu: [
    //   {
    //     name: 'about-project',
    //     slug: '/about-project',
    //   },
    //   {
    //     name: 'calculators',
    //     slug: '/#calculators',
    //   },
    //   // {
    //   //   name: 'online-courses',
    //   //   slug: '/online-courses',
    //   // },
    //   {
    //     name: 'last-news',
    //     slug: '/#last-news',
    //   },
    //   {
    //     name: 'last-events',
    //     slug: '/#events',
    //   },
    //   {
    //     name: 'contacts',
    //     slug: '/#contacts',
    //   },
    // ],
  },
  {
    name: 'useful-links',
    slug: '/korisni-posilannya',
  },
  {
    name: 'webinars',
    slug: '/webinars',
  },
  {
    name: 'applications-and-calculators',
    slug: '/applications-and-calculators',
  },
  {
    name: 'standards',
    slug: '/standarty-likuvannya',
  },
  // {
  //   name: 'new-clinical-cases',
  //   slug: '/clinical-cases',
  // },
  {
    name: 'share-with-patient',
    slug: '/doctor-and-visitor',
  },
  // {
  //   name: 'education',
  //   slug: '/education',
  // submenu: [
  //   {
  //     name: 'news',
  //     slug: '/news',
  //   },
  //   {
  //     name: 'calendar',
  //     slug: '/calendar',
  //   },
  //   {
  //     name: 'standards',
  //     slug: '/standards',
  //   },
  //   {
  //     name: 'practical-experience',
  //     slug: '/clinical-cases',
  //   },
  //   {
  //     name: 'video-library',
  //     slug: '/video-library',
  //   },
  //   {
  //     name: 'useful-links',
  //     slug: '/useful-links',
  //   },
  // ],
  // },
  // {
  //   name: 'cardio-community',
  //   slug: '/cardio-community',
  //   submenu: [
  //     {
  //       name: 'interesting-to-know',
  //       slug: '/interesting-to-know',
  //     },
  //     {
  //       name: 'debating-society',
  //       slug: '/debating-society',
  //     },
  //     {
  //       name: 'doctor-and-visitor',
  //       slug: '/doctor-and-visitor',
  //     },
  //     {
  //       name: 'questions-and-answers',
  //       slug: '/question-page',
  //     },
  //   ],
  // },
  // {
  //   name: 'applications-and-calculators',
  //   slug: '/applications-and-calculators',
  //   submenu: [
  //     {
  //       name: 'digital-solutions',
  //       slug: '/applications-and-calculators/digital-solutions',
  //     },
  //     {
  //       name: 'calculators',
  //       slug: '/applications-and-calculators/calculators',
  //     },
  //   ],
  // }
];
